import { useEffect, useState } from "react";
import { FetchReturn, MeetingStatus } from "../../../store";

import MeetingCancelationConfirm, {MeetingCancelationStatus, MeetingInfoProps} from "./steps/MeetingCancelationConfirm";
import { successConfetti } from "../../../utils/renderUtils";


type Props = {
  handleCancelMeeting: (message: string) => Promise<FetchReturn | undefined>;
};

export default function MeetingCancelation(
  {leaders, confirmed, handleCancelMeeting, ...meetingInfoProps}: MeetingInfoProps & Props
) {

  const [canceling, setCanceling] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(MeetingCancelationStatus.INITIAL);

  useEffect(() => {
    if (meetingInfoProps.meeting?.status.id === MeetingStatus.CANCELLED) {
      setCancelStatus(MeetingCancelationStatus.CANCELED);
    }
  }, [meetingInfoProps]);

  const cancelMeeting = async (message: string) => {
    setCanceling(true);
    const res = await handleCancelMeeting(message);
    setCanceling(false);
    if (res?.status === 200) {
      setCancelStatus(MeetingCancelationStatus.SUCCESS);
      successConfetti();
    } else {
      setCancelStatus(MeetingCancelationStatus.ERROR);
    }
  };


  return <MeetingCancelationConfirm
    {...meetingInfoProps}
    cancelMeeting={cancelMeeting}
    canceling={canceling}
    leaders={leaders}
    confirmed={confirmed}
    cancelStatus={cancelStatus}
  />;
}