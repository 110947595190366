import { Box, FormLabel, Grid, Skeleton, styled, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import colors from "../../../colors";
import StaticDayPicker from "../../../components/Meeting/StaticDayPicker";
import TimeSlots from "../../../components/Meeting/TimeSlots";
import { BookingSlot, BookingSlotsByDay } from "../../../store";
import { CabButton } from "@CabComponents/CabButton";
import { CabTimezoneInput } from "@CabComponents/CabTimezoneInput";
import CabSpinner from "@CabComponents/CabSpinner";

const StyledBox = styled(Box, { label: "StyledBox" })(() => ({
  maxWidth: '750px',
  minHeight: '400px',
  marginTop: 8,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: '12px',
  paddingBottom: '0px',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '30px',
  backgroundColor: colors.white900
}));

const ScrollBox = styled(Box, { label: "ScrollBox" })({
  overflow: 'auto',
  height: "fit-content",
  marginBottom: 12,
  maxHeight: 280,
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingRight: 5,
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: colors.white900,
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black300,
    borderRadius: 4,
  }
});

const dayFormat = 'cccc MMMM d, yyyy';

export interface DateTimeSelectionProps {
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  slotsByDay: BookingSlotsByDay;
  currentTimezone: string;
  from: string;
  slotSelected: BookingSlot | null;
  isPreview?: boolean;
  hideTitle?: boolean;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void
  loading?: boolean;
  defaultYearMonth: [number, number] | null
  viewDate: DateTime;
  isEnterpriseBook?: boolean;
  setNoTimesWork: (value: boolean) => void;
  showSkeletons?: boolean;
}

const DateTimeSelection = ({
  selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, from, slotSelected, isPreview, hideTitle,
  setCurrentTimeZone, handleDaySelected, setSlotSelected, onConfirm, isFramed, onCalendarDateChange, loading,
  defaultYearMonth, viewDate, isEnterpriseBook, setNoTimesWork, showSkeletons
}: DateTimeSelectionProps): ReactElement => {

  // if a conflicting day is selected with a link clear the selected day
  // useEffect(() => {
  //   if (!selectedDay || !slotsByDay[selectedDay.toMillis()]) {
  //     handleDaySelected(null);
  //   }
  // }, [handleDaySelected, selectedDay, slotsByDay, currentTimezone]);

  const selectedDayIsAvailable = selectedDay && orderedAvailableDates.some(d => d.hasSame(selectedDay, 'day'));

  const handleSetCurrentTimeZone = (value: string | null) => {
    if (value != null) {
      setCurrentTimeZone(value);
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {!(isEnterpriseBook || hideTitle) && (
        <Typography variant="h1" fontSize={56}>Hello!</Typography>
      )}
      {!(isFramed || isEnterpriseBook) && (
        showSkeletons ? (
          <>
            <Skeleton variant='rounded' height={16} sx={{maxWidth: '620px'}}/>
            <Skeleton variant='rounded' height={16} sx={{maxWidth: '620px'}}/>
          </>
        ) : (
          <Typography variant="h5" maxWidth={'620px'} marginTop={hideTitle ? 4 : 0}>
            <b>{from}</b>&nbsp;
            has invited you to a meeting and has prepared some dates and times below. Please select one to get started.
          </Typography>
        )
      )}
      <Box display='flex' flexDirection='column' gap={1}>
        <Box maxWidth='300px'>
          <FormLabel htmlFor="timezone-select">Timezone</FormLabel>
          <CabTimezoneInput
            id="timezone-select"
            label='Timezone'
            value={currentTimezone ? currentTimezone : ''}
            required={true}
            defaultValue={currentTimezone ? currentTimezone : ''}
            onChange={handleSetCurrentTimeZone}
            sx={{ width: '100%' }}
          />
        </Box>
        {/* {orderedSlotsByDay.length ? ( */}
        <StyledBox>
          <Grid container spacing={8} rowSpacing={3}>
            <Grid item sm={6}>
              <Box display='flex' flexDirection='column' gap={2} height='100%'>
                <Typography variant="h5">Select date and time</Typography>
                <Box position="relative" height='100%'>
                  {showSkeletons ? (
                    <Skeleton height='100%' width='100%' variant='rounded' />
                  ) : (
                    <StaticDayPicker
                      selectedDay={selectedDay}
                      orderedAvailableDates={orderedAvailableDates}
                      onDaySelected={handleDaySelected}
                      onCalendarDateChange={onCalendarDateChange}
                      defaultYearMonth={defaultYearMonth}
                      timezone={currentTimezone}
                      slotsLoading={!!loading}
                    />
                  )}
                  
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box maxWidth={'300px'} display='flex' flexDirection='column' gap={2} height={'100%'}>
                {showSkeletons ? (
                  <Skeleton variant='rounded' width='100%' height={26} />
                ) : (
                  <Typography variant="h5">
                    {selectedDayIsAvailable ? selectedDay.toFormat(dayFormat) : 'Please Select a Day'}
                  </Typography>
                )}
                {showSkeletons ? (
                  <Skeleton height='100%' width='100%' variant='rounded' sx={{minHeight: '330px'}}/>
                ) : (
                  <>
                    {!loading ? (
                      <Box>
                        <ScrollBox>
                          {selectedDayIsAvailable ? (
                            <TimeSlots
                              slots={selectedDay
                                ? slotsByDay[selectedDay.startOf('day').toMillis()] || []
                                : []}
                              currentTimeZone={currentTimezone}
                              slotSelected={slotSelected}
                              setSlotSelected={setSlotSelected}
                              onConfirm={onConfirm}
                              // isPreview={isPreview}
                            />
                          ) : (
                            orderedAvailableDates.length ? (
                              <Box display='flex' height='100%' width='100%' 
                                alignItems='center' justifyContent='center'>
                                No Day Selected
                              </Box>
                            ) : (
                              <Box display='flex' height='100%' width='100%' alignItems='center'
                                justifyContent='center' flexDirection='column' gap={1}>
                                <Typography fontSize={15}>
                                  There are no available times in {viewDate.toLocaleString({ month: 'long' })}
                                </Typography>
                                {onCalendarDateChange && (
                                  <CabButton
                                    onClick={() => onCalendarDateChange(viewDate.plus({ month: 1 }).set({ day: 1 }))}
                                    buttonType='tertiary'
                                  >
                                    Check Next Month
                                  </CabButton>
                                )}
                              </Box>
                            )
                          )}
                        </ScrollBox>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        width="100%"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CabSpinner scale={4} color='inherit' data-testid="calendar-spinner"/>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            
          </Grid>
          <Box sx={{width: "100%"}} display={"flex"} flexDirection={"row-reverse"}>
            <CabButton
              color='primary'
              buttonType='text'
              sx={{
                height: 40,
                marginBottom: 4,
                textDecoration: "underline",
                paddingRight: 3,
                "&:hover": {
                  backgroundColor: "unset"
                }
              }}
              onClick={() => setNoTimesWork(true)}
            >
              No Times Work
            </CabButton>
          </Box>
        </StyledBox>
        {/* // ) : (
        //   <StyledBox display='flex' alignItems='center' justifyContent='center'>
        //     <b>There are no remaining times available</b>
        //   </StyledBox>
        // )} */}
        
      </Box>
      
    </Box>
  );
};

export default DateTimeSelection;