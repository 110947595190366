import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { CabButton, CabTooltip } from '@CabComponents';
import classes from './TimeSlots.module.css'; 
import colors from '../../colors';
import { Box } from '@mui/material';
import { BookingSlot } from '../../store';

export interface TimeSlotsProps {
  slots: BookingSlot[];
  currentTimeZone: string;
  isPreview?: boolean;
  slotSelected: BookingSlot | null;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
}

const TimeSlots = ({
  slots, currentTimeZone, slotSelected, isPreview, setSlotSelected, onConfirm
}: TimeSlotsProps): ReactElement => {
  return <>
    {
      slots.map(slot => {
        const timeText = DateTime.fromISO(slot.start, { zone: currentTimeZone }).toFormat('h:mm a') + ' - ' 
          + DateTime.fromISO(slot.end, {zone: currentTimeZone}).toFormat('h:mm a');
        return (
          slotSelected?.start === slot.start
            ?
            <Box key={slot.start} width='100%' className={classes.selectedSlotContainer}>
              <CabButton 
                className={classes.selectedSlotButtonTime} color="accent" sx={{ backgroundColor: colors.navyDark }}
              >
                {timeText}
              </CabButton>
              <CabButton 
                className={classes.selectedSlotButtonConfirm}
                onClick={onConfirm}
              >
                Confirm
              </CabButton>
            </Box> 
            : (
              <Box key={slot.start} width='100%' className={classes.unselectedSlotContainer}> 
                {isPreview ? (
                  <CabTooltip
                    title='This is just a preview. When your 
                    invited participant clicks this button, Cabinet will schedule the meeting on your behalf'
                    placement='bottom'
                    wrapWithSpan
                  >
                    <CabButton 
                      className={classes.unselectedSlotButton} 
                      buttonType="tertiary"
                      color="accent"
                      sx={{backgroundColor: colors.white900}}
                    >
                      {timeText}
                    </CabButton>
                  </CabTooltip>
                ) : (
                  <CabButton 
                    className={classes.unselectedSlotButton} 
                    onClick={() => setSlotSelected(slot)}
                    buttonType="tertiary"
                    color="accent"
                    sx={{backgroundColor: colors.white900}}
                  >
                    {timeText}
                  </CabButton>
                )}
              </Box> 
            )
        );
      })
    }
  </>;
};

export default TimeSlots;