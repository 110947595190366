import { CabButton } from "@CabComponents/CabButton";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, FormLabel, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import colors from "../../../colors";
import { emailRegex } from "../../../constants";
import { 
  ExternalMeetingInfo, 
  NormalizedExternalParticipant 
} from "../../../store";

import { CabTooltip } from "@CabComponents/CabTooltip";
import CabSpinner from "@CabComponents/CabSpinner";


export interface NoTimesWorkProps {
  meeting: ExternalMeetingInfo | null;
  isBooking: boolean;
  isReschedulingURL?: boolean;
  isPreview?: boolean;
  prefillName?: string;
  prefillEmail?: string;
  onSubmit: (data: NormalizedExternalParticipant) => void
}

const NoTimesWork = ({
  meeting, isBooking, isReschedulingURL, isPreview,
  prefillEmail, prefillName, onSubmit
}: NoTimesWorkProps): ReactElement => {

  const defaultParticipant: NormalizedExternalParticipant = {
    id: -1, name: prefillName || "", email: prefillEmail || "",
    first_response_date: null, meeting: meeting?.id || -1, email_hash: '', required: true,
    no_times_comment: null, calendar_access: null
  };

  const {
    control, formState, handleSubmit
  } = useForm<NormalizedExternalParticipant>
  ({defaultValues: defaultParticipant});
  
  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant="h1" fontSize={38}>Send a notice to the organizer</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={3}>

          <Box display='flex' flexDirection='column' gap={2}>
            <Controller 
              name={`name`}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                  <Box display='flex' justifyContent='space-between'>
                    <FormLabel>Name*</FormLabel>
                  </Box>
                  <CabTextInput
                    {...field}
                    inputRef={ref}
                    sx={{width: '98%'}}
                    required
                  />
                </Box>
              )}/>
            <Controller 
              name={`email`}
              control={control}
              rules={{ required: true, pattern: emailRegex }}
              render={({ field: { ref, ...field } }) => (
                <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                  <FormLabel>Email*</FormLabel>
                  <CabTextInput
                    {...field}
                    type='email'
                    inputRef={ref}
                    sx={{width: '98%'}}
                    required
                  />
                </Box>
              )}/>
            <Controller 
              name={`no_times_comment`}
              control={control}
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => (
                <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                  <FormLabel>Comment</FormLabel>
                  <CabTextInput
                    {...field}
                    multiline
                    minRows={4}
                    type='email'
                    inputRef={ref}
                    sx={{width: '98%'}}
                  />
                </Box>
              )}/>
          </Box>
  
          <Box display='flex' gap={1} alignItems='center'>
            <CabTooltip
              title={ isPreview ? 'This is just a preview. When your invited participant clicks this button, ' +
              'Cabinet will notify the meeting creator on your behalf' : ''}
              placement='top-start'
              wrapWithSpan
            >
              <Box>
                <CabButton 
                  color='primary'
                  disabled={!formState.isValid}
                  type={!isPreview ? 'submit' : undefined}
                  sx={{padding: 2, width: '170px'}}
                >
                  Send Notification
                </CabButton>
                {isPreview && (
                  <Typography color={colors.black700} marginTop={1}>
                    (This is a preview, the meeting creator cannot be notified)
                  </Typography>
                )}
              </Box>
            </CabTooltip>
            {isBooking &&
              <CabSpinner scale={2} color='inherit' />
            }
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default NoTimesWork;
