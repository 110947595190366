import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Alert, Box, FormControl, FormLabel, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement, useEffect } from "react";
import { Control, Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import colors from "../../../colors";
import { emailRegex } from "../../../constants";
import { 
  BookingSlot, ExternalMeetingInfo, MeetingQuestion, MeetingQuestionAnswer, MeetingQuestionAnswerSubmission, 
  NormalizedExternalParticipant 
} from "../../../store";
import { useMountEffect } from "../../../utils/hooks";
import { CabTooltip } from "@CabComponents/CabTooltip";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoAdd, IoCloseCircleOutline } from "react-icons/io5";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { QuestionType } from "../../../store/schedule/types";
import { getAnswerString } from "../../../utils/scheduleUtils";


export interface AttendeeInfoProps {
  selectedDay: DateTime | null;
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  meeting: ExternalMeetingInfo | null;
  isBooking: boolean;
  allowAddParticipants: boolean;
  isReschedulingURL?: boolean;
  isPreview?: boolean;
  setName: (name: string) => void;
  setLocalParticipants: (participants: NormalizedExternalParticipant[]) => void;
  setQuestionAnswers: (questionAnswers: {questionId: number, question: string, answer: string}[]) => void;
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  fetchMeetingQuestionAnswers: () => Promise<{answers: {[id: number]: MeetingQuestionAnswer}} | undefined>
  prefillName?: string;
  prefillEmail?: string;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
}

const dayFormat = 'cccc MMMM d, yyyy (ZZZZ)';

const AttendeeInfo = ({
  selectedDay, currentTimezone, slotSelected, meeting, isBooking, isReschedulingURL, setName, setQuestionAnswers, 
  handleScheduleMeeting, fetchMeetingQuestionAnswers, setLocalParticipants, allowAddParticipants, isPreview,
  prefillEmail, prefillName, rebookParticipants, rebookAnswers
}: AttendeeInfoProps): ReactElement => {

  const defaultParticipant: NormalizedExternalParticipant = {
    id: -1, name: '', email: '', first_response_date: null, meeting: meeting?.id || -1, email_hash: '', required: true,
    no_times_comment: null, calendar_access: null
  };

  const {
    control, getValues, setValue, watch, formState, setFocus, reset
  } = useForm<{participants: NormalizedExternalParticipant[], answers: MeetingQuestionAnswerSubmission[]}>
  ({defaultValues: {'participants': rebookParticipants.length ? rebookParticipants : [defaultParticipant], 
    'answers':[]}});

  const { fields, append, remove } = useFieldArray({control, name: 'participants'});

  const handleSchedule = (e: React.FormEvent) => {
    if (meeting && meeting.questions) {
      handleScheduleMeeting(getValues()['participants'], getValues()["answers"]);
      setLocalParticipants(getValues()['participants']);
    } else {
      handleScheduleMeeting(getValues()['participants']);
      setLocalParticipants(getValues()['participants']);
    }
    e.preventDefault();
  };

  const name = useWatch({name: 'participants', control});
  const answers = watch('answers');

  useMountEffect(() => {
    setFocus(`participants.${0}.name`);
    if (prefillEmail && prefillName) {
      setValue(`participants.${0}.name`, prefillName);
      setValue(`participants.${0}.email`, prefillEmail);
      setFocus(`participants.${0}.email`);
    }
  });

  // This is done in a useEffect down below
  // useEffect(() => {
  //   const meetingParticipants = Object.values(meeting?.participants || {});
  //   if (meeting?.participants && meetingParticipants.length > 0) {
  //     setValue('participants', meetingParticipants);
  //   }
  // }, [meeting?.participants, setValue]);

  useEffect(() => {
    if (rebookAnswers.length) {
      setValue('answers', rebookAnswers);
    }
  }, [rebookAnswers, setValue]);

  useEffect(() => {
    if (isReschedulingURL) {
      fetchMeetingQuestionAnswers().then((curAnswers) => {
        const orderedQuestionAnswers: MeetingQuestionAnswerSubmission[] = [];
        if (curAnswers) {
          meeting?.questions?.forEach((question) => {
            const answerObj = Object.values(curAnswers["answers"])
              .find(curAnswer => curAnswer.question === question.id);
            const answer = {
              id: answerObj?.id || -1,
              question: question.id,
              text: answerObj?.text || "",
              options: answerObj?.options || []
            };
            orderedQuestionAnswers.push({...answer, participant: undefined});
          });
        }
        reset({
          participants: meeting?.participants && Object.keys(meeting.participants).length > 0
            ? Object.values(meeting?.participants) : [defaultParticipant],
          answers: orderedQuestionAnswers
        });
      });
    }
    // This was triggering way too often - we only really want it to trigger when the meeting changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReschedulingURL, setValue, meeting]);
  
  useEffect(() => {
    setName(name[0].name);
  }, [name, setName]);

  const questionAnswers = meeting?.questions?.map((question, index) => (
    {
      questionId: question.id, 
      question: question.title, 
      answer: getAnswerString(answers[index], question)
    }
  ));

  useEffect(() => {
    setQuestionAnswers(questionAnswers || []);
  }, [questionAnswers, setQuestionAnswers]);

  useEffect(() => {
    if (meeting?.questions && !rebookAnswers.length) {
      const orderedQuestionAnswers: MeetingQuestionAnswerSubmission[] = [];
      meeting.questions.forEach((question) => {
        orderedQuestionAnswers.push({
          id: -1,
          question: question.id,
          text: "",
          options: []
        });
      });
      setValue("answers", orderedQuestionAnswers);
    }
  }, [meeting, setValue, rebookAnswers]);

  const startTime = slotSelected?.start ? 
    DateTime.fromISO(slotSelected?.start).setZone(currentTimezone).toFormat('h:mm a') : '';

  const handleEnterSubmit = (key: string) => {
    if (key === 'Enter' && formState.isValid) {
      if (meeting && meeting.questions) {
        handleScheduleMeeting(getValues()['participants'], getValues()["answers"]);
        setLocalParticipants(getValues()['participants']);
      } else {
        handleScheduleMeeting(getValues()['participants']);
        setLocalParticipants(getValues()['participants']);
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant="h1" fontSize={38}>Confirm your meeting</Typography>
        <Typography variant="body1" fontSize={'22px'} maxWidth={'620px'}>
          {`${startTime} on ${selectedDay?.toFormat(dayFormat)}`}
        </Typography>
      </Box>
      <form onSubmit={handleSchedule}>
        <Box display='flex' flexDirection='column' gap={3}>
          {fields.map((participant, index) => {
            return (
              <Box key={participant.id} display='flex' flexDirection='column' gap={2}>
                <Controller 
                  name={`participants.${index}.name`}
                  defaultValue={`${participant.name}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                      <Box display='flex' justifyContent='space-between'>
                        <FormLabel>Name*</FormLabel>
                        {index !== 0 && 
                        <IconButton edge='end' aria-label='remove' disableRipple sx={{padding: 0}}
                          onClick={() => remove(index)}
                        >
                          <CabIcon Icon={IoCloseCircleOutline} alt='Remove' />
                        </IconButton>
                        }
                      </Box>
                      <CabTextInput
                        {...field}
                        inputRef={ref}
                        sx={{width: '98%'}}
                        onKeyDown={(e) => handleEnterSubmit(e.key)}
                        required
                      />
                    </Box>
                  )}/>
                <Controller 
                  name={`participants.${index}.email`}
                  defaultValue={`${participant.email}`}
                  control={control}
                  rules={{ required: true, pattern: emailRegex }}
                  render={({ field: { ref, ...field } }) => (
                    <Box maxWidth={'500px'} display='flex' flexDirection='column'>
                      <FormLabel>Email*</FormLabel>
                      <CabTextInput
                        {...field}
                        type='email'
                        inputRef={ref}
                        sx={{width: '98%'}}
                        required
                      />
                      <Typography color={colors.black700}>
                        An invite will be sent to this address
                      </Typography>
                    </Box>
                  )}/>
              </Box>
            );
          })}
          {allowAddParticipants && (
            <Box>
              <CabButton
                buttonType="tertiary"
                color='accent'
                onClick={() => append(defaultParticipant)}
                icon={<CabIcon Icon={IoAdd} alt='Add'/>}
              >
                Add Participant
              </CabButton>
            </Box>
          )}
          {meeting?.questions?.length !== undefined && meeting.questions.length > 0 && (
            <Box display='flex' flexDirection='column' gap={2} maxWidth={'700px'}>
              <Typography variant="h1" fontSize={20} lineHeight={'41px'} marginTop={1}>
                Additional Meeting Questions
              </Typography>
              <Box display='flex' flexDirection='column' rowGap={4} maxWidth={'500px'}>
                {meeting.questions.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question, idx) => {
                  return <Question
                    key={question.id}
                    question={question}
                    control={control}
                    idx={idx}
                  />;
                })}
              </Box>
            </Box>
          )}
          <Box display='flex' gap={1} alignItems='center'>
            <CabTooltip
              title={ isPreview ? 'This is just a preview. When your invited participant clicks this button, ' +
              'Cabinet will schedule the meeting on your behalf.' : ''}
              placement='top-start'
              wrapWithSpan
            >
              <Box>
                <CabButton 
                  color='primary'
                  type={!isPreview ? 'submit' : undefined}
                  disabled={isBooking || !formState.isValid}
                  sx={{padding: 2, width: '170px'}}
                >
                  Book Meeting
                </CabButton>
                {isPreview && (
                  <Typography color={colors.black700} marginTop={1}>
                    (This is a preview, the meeting cannot be booked from here)
                  </Typography>
                )}
              </Box>
            </CabTooltip>
            {isBooking &&
            <Box>
              <CabSpinner scale={2} />
            </Box>
            }
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AttendeeInfo;

type QuestionProps = {
  question: MeetingQuestion
  control: Control<{
    participants: NormalizedExternalParticipant[],
    answers: MeetingQuestionAnswerSubmission[];
  }>
  idx: number;
};

const Question = ({question, control, idx}: QuestionProps) => {
  return (
    <FormControl fullWidth>
      <FormLabel>{question.required ? question.title + ' *' : question.title}</FormLabel>
      {question.question_type === QuestionType.TEXT ? (
        <Controller
          control={control}
          name={`answers.${idx}.text`}
          rules={{
            required: question.required,
            maxLength: 4000
          }}
          render={({field: {onChange, value, ref}, fieldState: {error, invalid}}) => <Box sx={{width: '100%'}}>
            <CabTextInput
              multiline
              fullWidth
              minRows={1}
              maxRows={6}
              required={question.required}
              inputRef={ref}
              value={value}
              onChange={onChange}
            />
            <div>
              {error &&
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {error?.type === "required" && "This field is required"}
                  {error?.type === "maxLength" && "This field should be less that 4000 characters"}
                </Alert>
              }
            </div>
          </Box>} 
        
        />
      ) : (
        <Controller
          control={control}
          name={`answers.${idx}.options`}
          rules={{
            required: question.required
          }}
          render={({field: {onChange, value}, fieldState: {error}}) => <Box sx={{width: '100%'}}>
            <CabAutocomplete<number, never>
              value={question.question_type === QuestionType.MULTI_SELECT 
                ? value ?? [] 
                : value && value.length ? value[0] : -1}
              multiple={question.question_type === QuestionType.MULTI_SELECT}
              placeholder={ !value || value.length === 0 ? "Select an answer" : ""}
              options={question.options ? question.options.map(option => ({
                value: option.id,
                label: option.name
              })) : []}
              // This is to assure we always return an array
              onChange={(v) => onChange(!v ? [] : v instanceof Array ? v : [v])}
            />
          </Box>} 
        
        />
      )}
    </FormControl>
  );
};