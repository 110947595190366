import { Alert, Box, FormControl, FormLabel, styled, Typography, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement, useEffect, useState } from "react";
import colors from "../../../colors";
import {
  BookingSlot, ExternalMeetingInfo, MeetingQuestionAnswer, MeetingQuestionAnswerSubmission,
  MeetingStatus, NormalizedExternalParticipant
} from "../../../store";
import { getAnswerString } from "../../../utils/scheduleUtils";

const StyledBox = styled(Box, { label: "StyledBox" })(() => ({
  maxWidth: '900px',
  marginTop: 8,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: '12px',
  padding: '40px',
  backgroundColor: colors.white900,
  display: 'flex',
  flexDirection: 'column',
  gap: 16
}));

const dayFormat = 'cccc MMMM d, yyyy';

export interface ConfirmationProps {
  selectedDay: DateTime | null;
  slotSelected: BookingSlot | null;
  meeting: ExternalMeetingInfo | null;
  currentTimezone: string;
  fetchMeetingQuestionAnswers: (externalId?: string) => Promise<{
    answers: { [id: number]: MeetingQuestionAnswer }
  } | undefined>
  localParticipants?: NormalizedExternalParticipant[];
  confirmed: boolean;
  isBadResponse: boolean;
  noTimesWork: boolean
  noTimesWorkSent: boolean
}

const Confirmation = ({
  selectedDay, meeting, slotSelected, currentTimezone, fetchMeetingQuestionAnswers, localParticipants, confirmed, 
  isBadResponse, noTimesWork, noTimesWorkSent
}: ConfirmationProps): ReactElement => {
  const [answers, setAnswers] = useState<MeetingQuestionAnswerSubmission[]>([]);
  const [loadingAnswers, setLoadingAnswers] = useState(true);

  const user = meeting?.create_user;
  const startTime = meeting?.status.id === MeetingStatus.SCHEDULED ? meeting?.event_start_time : slotSelected?.start;
  const daySelected = meeting?.status.id === MeetingStatus.SCHEDULED ? DateTime.fromISO(meeting?.event_start_time || '')
    .toFormat(dayFormat) : 
    DateTime.fromISO(slotSelected?.start || '').toFormat(dayFormat);
  const start = startTime ? DateTime.fromISO(startTime).setZone(currentTimezone).toFormat('h:mm a') : '';
  const end = startTime ? DateTime.fromISO(startTime)
    .setZone(currentTimezone)
    .plus({ minutes: meeting?.duration_minutes })
    .toFormat('h:mm a (ZZZZ)') : '';

  useEffect(() => {
    if (meeting && meeting.questions) {
      setLoadingAnswers(true);
      fetchMeetingQuestionAnswers(meeting.external_id).then((curAnswers) => {
        const orderedQuestionAnswers: MeetingQuestionAnswerSubmission[] = [];
        if (curAnswers) {
          meeting?.questions?.forEach((question) => {
            const answer = Object.values(curAnswers["answers"]).find(curAnswer => curAnswer.question === question.id);
            if (answer) {
              orderedQuestionAnswers.push({ ...answer, participant: undefined });
            }
          });
          if (orderedQuestionAnswers.length) {
            setAnswers(orderedQuestionAnswers);
          }
        }
      });
    }
    setLoadingAnswers(false);
  }, [fetchMeetingQuestionAnswers, meeting]);

  const isErrorNotice = isBadResponse || (noTimesWork && !noTimesWorkSent);

  return (
    meeting?.status.id !== MeetingStatus.CANCELLED ? (
      <Box display='flex' flexDirection='column' gap={4}>
        <Typography variant="h1" fontSize={42}>
          {!startTime ? (
            (noTimesWork ? <>Notice Sent!</> : <>Thanks for your submission!</>)
          ) : (
            isErrorNotice ? (
              (noTimesWork ?
                <>Unable to send notice</>
                :
                <>Meeting Not Scheduled</>)
            ) : (
              noTimesWork ?
                <>Notice Sent</>
                :
                <>Meeting Scheduled!</>
            ))}
        </Typography>
        {confirmed ? (
          <Typography variant="h5" fontWeight={400} maxWidth={'620px'}>
            A meeting invitation will be sent to the email(s) that you provided.
          </Typography>
        ) : (
          isErrorNotice ? (
            <Box>
              <Typography variant="h5" fontWeight={400} maxWidth={'720px'}>
                We're sorry, something went wrong. Please contact:&nbsp; 
                <b>{`${user?.email}`}</b>
              </Typography>
            </Box>
          ) : (
            meeting?.status.id === MeetingStatus.SCHEDULED ? (
              <Box>
                <Typography variant="h5" fontWeight={400} maxWidth={'620px'}>
                  <b>{`${user?.first_name} ${user?.last_name}`}</b>&nbsp;
                  has scheduled this meeting.
                </Typography>
                <Typography variant="h5" fontWeight={400} maxWidth={'620px'}>
                  You should receive a calendar invite shortly, if you have not already.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" fontWeight={400} maxWidth={'620px'}>
                  <b>{`${user?.first_name} ${user?.last_name}`}</b>&nbsp;
                  has been notified of your response.
                </Typography>
                {!noTimesWork &&
                  <Typography variant="h5" fontWeight={400} maxWidth={'620px'}>
                    You will receive a calendar invite once the event has been scheduled.
                  </Typography>
                }
              </Box>
            )
          ))}
        {!isBadResponse &&
          <StyledBox>
            <Typography variant="h3" fontSize={26} fontWeight={700}>
              {!noTimesWork ? "Meeting Confirmation" : "Notice Confirmation"}
            </Typography>
            <Box display='flex' gap={4} flexWrap='wrap'>
              {localParticipants && localParticipants?.length > 0 ? (
                localParticipants.map((participant, index) => {
                  return <Box key={index}>
                    <Typography variant="h2">{participant.name}</Typography>
                    <Typography variant="h2">{participant.email}</Typography>
                  </Box>;
                })
              ) : (
                meeting && meeting.participants &&
                Object.values(meeting.participants).map(participant => {
                  return <Box key={participant.id}>
                    <Typography variant="h2">{participant.name}</Typography>
                  </Box>;
                })
              )}
            </Box>
            <Box display='flex' flexDirection='column' width={'fit-content'} marginTop={2} gap={1}>
              {startTime ? (
                <>
                  <Typography variant='h5' fontWeight={700}>Meeting Time</Typography>
                  <TimeSlot
                    selectedDay={meeting?.status.id === MeetingStatus.SCHEDULED && meeting.event_start_time
                      ? DateTime.fromISO(meeting.event_start_time).setZone(currentTimezone).toFormat(dayFormat)
                      : selectedDay?.toFormat(dayFormat) || daySelected}
                    start={start}
                    end={end}
                  />
                </>
              ) : (
                <>
                  {!noTimesWork &&
                  <Alert severity='info' sx={{ maxWidth: 'fit-content', marginBottom: 1, minWidth: 150 }}>
                    <span>This meeting has been scheduled outside of Cabinet</span>
                  </Alert>
                  }
                </>
              )}
            </Box>
            {meeting?.allow_reschedule_cancel && (
              <Typography variant="body2" color={colors.black800}>
                {startTime ? (
                  <>Use the links on your calendar event to reschedule or cancel this meeting</>
                ) : (
                  <>Email <b>{meeting.create_user.email}</b> to make changes to this event</>
                )}
              </Typography>
            )}
            {meeting?.questions && meeting?.questions?.length > 0 && answers && (
              <Box marginTop={2}>
                <Box width={'100%'} borderBottom='1px solid' borderColor={colors.black200}></Box>
                <QuestionResponses questions={meeting?.questions} answers={answers} loading={loadingAnswers} />
              </Box>
            )}
          </StyledBox>
        }
      </Box>
    ) : (
      // Meeting is Cancelled
      <Alert severity='info' sx={{ maxWidth: 'fit-content', marginBottom: 1, minWidth: 150 }}>
        This meeting has been <b>{meeting.status.name}</b>. 
        Reach out to <b>{meeting.create_user.email}</b> to schedule.
      </Alert>
    )
  );
};

export default Confirmation;

type TimeSlotProps = {
  selectedDay: string;
  start: string;
  end: string;
};

const TimeSlot = ({ selectedDay, start, end }: TimeSlotProps) => {
  const timeStr = `${selectedDay} at ${start} - ${end}`;

  return (
    <Box sx={{
      backgroundColor: colors.navyPrimary,
      borderColor: colors.navyPrimary,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 1,
      paddingLeft: 3,
      paddingRight: 3,
      textAlign: 'center',
      color: colors.white900,
    }} >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingTop={'12px'}
        paddingBottom={'12px'}
        height={48}
      >
        <Typography variant="body2" fontWeight={500} color={colors.white900}>{timeStr}</Typography>
      </Box>
    </Box>
  );
};

type QuestionProps = {
  questions: ExternalMeetingInfo['questions'] | undefined
  answers: MeetingQuestionAnswerSubmission[];
  loading: boolean;
};

const QuestionResponses = ({ questions, answers, loading }: QuestionProps) => {
  return (
    <Box marginTop={1}>
      {questions?.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question) => {
        const answer = answers.find(curAnswer => curAnswer.question === question.id);
        return (
          <FormControl key={question.id} fullWidth>
            <FormLabel sx={{ marginTop: 2 }}>{question.required ? question.title + ' *' : question.title}</FormLabel>
            {loading ? (
              <Skeleton width={200} height={30} />
            ) : (
              <>
                {answer ? (
                  <Box sx={{ width: '100%' }}>
                    <Typography marginTop={1} fontWeight={600}>
                      {getAnswerString(answer, question, '- no answer -')}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ width: '100%' }}>
                    <Typography marginTop={1} fontWeight={600}> - no answer - </Typography>
                  </Box>
                )}
              </>
            )}
          </FormControl>
        );
      })}
    </Box>
  );
};