import { Box, FormLabel, Skeleton, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import MobileDayPicker from "../../../components/Meeting/MobileDayPicker";
import StaticDayPicker from "../../../components/Meeting/StaticDayPicker";
import TimeSlots from "../../../components/Meeting/TimeSlots";
import { BookingSlot, BookingSlotsByDay } from "../../../store";
import { DateTime } from "luxon";
import { CabButton } from "@CabComponents/CabButton";
import { CabTimezoneInput } from "@CabComponents/CabTimezoneInput";
import CabSpinner from "@CabComponents/CabSpinner";


export interface MobileDateTimeSelectionProps {
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  slotsByDay: BookingSlotsByDay;
  currentTimezone: string;
  from: string;
  slotSelected: BookingSlot | null;
  isPreview?: boolean;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
  isSelectedDay?: boolean;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void
  loading: boolean;
  defaultYearMonth: [number, number]|null;
  setNoTimesWork: (value: boolean) => void;
  showSkeletons?: boolean;
}

const MobileDateTimeSelection = ({
  selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, slotSelected, isPreview, from, setCurrentTimeZone,
  handleDaySelected, setSlotSelected, onConfirm, isSelectedDay, isFramed, onCalendarDateChange, loading,
  defaultYearMonth, setNoTimesWork, showSkeletons
}: MobileDateTimeSelectionProps): ReactElement => {
  const [isDaySelected, setIsDaySelected] = useState(isSelectedDay ? true : false);

  const onSelectDay = (daySelected: DateTime | null) => {
    handleDaySelected(daySelected);
    setIsDaySelected(!!daySelected);
  };

  const handleSetCurrentTimeZone = (value: string | null) => {
    if (value != null) {
      setCurrentTimeZone(value);
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      {!isFramed && (
        showSkeletons ? (
          <Box marginTop={4}>
            <Skeleton height={21} />
            <Skeleton height={21} />
          </Box>
        ) : (
          <Typography marginTop={4} variant="body1" maxWidth={'620px'}>
            <b>{from}</b>&nbsp;
            has invited you to a meeting and has prepared some dates and times below. Please select one to get started.
          </Typography>
        )
      )}
      <Box width={"fit-content"} margin="auto" >
        <Box width='350px' marginBottom={1}>
          <FormLabel>Timezone</FormLabel>
          <CabTimezoneInput
            label='Timezone'
            value={currentTimezone ? currentTimezone : ''}
            required={true}
            defaultValue={currentTimezone ? currentTimezone : ''}
            onChange={handleSetCurrentTimeZone}
            sx={{ width: '100%' }}
          />
        </Box>
        <Typography variant="h5">Select date and time</Typography>
        {showSkeletons ? (
          <Skeleton height='100%' width='100%' variant='rounded' sx={{minHeight: '330px'}}/>
        ) : (
          <>
            {!isDaySelected ? (
              <Box width='fit-content' position="relative">
                {loading &&
              <Box position="absolute" width="100%" height="100%" top={0} left={0} zIndex={20}>
                <Box 
                  display="flex"
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CabSpinner scale={4} color='inherit' data-testid="calendar-spinner"/>
                </Box>
              </Box>
                }
                <StaticDayPicker
                  selectedDay={selectedDay}
                  noDefault
                  orderedAvailableDates={orderedAvailableDates}
                  onDaySelected={onSelectDay}
                  onCalendarDateChange={onCalendarDateChange}
                  defaultYearMonth={defaultYearMonth}
                  timezone={currentTimezone}
                  slotsLoading={loading}
                />
              </Box>
            ) : (
              <Box maxWidth='350px'>
                <MobileDayPicker
                  selectedDay={selectedDay}
                  onDaySelected={handleDaySelected}
                  orderedAvailableDates={orderedAvailableDates}
                  onCalendarDateChange={onCalendarDateChange}
                  timezone={currentTimezone}
                  slotsLoading={loading}
                />
              </Box>
            )}
          </>
        )}
        {isDaySelected && (
          <Box marginTop={2} maxWidth={'350px'}>
            <TimeSlots
              slots={selectedDay
                ? slotsByDay[selectedDay.toMillis()] || []
                : []}
              currentTimeZone={currentTimezone}
              slotSelected={slotSelected}
              setSlotSelected={setSlotSelected}
              onConfirm={onConfirm}
            />
          </Box>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
        <CabButton
          color='primary'
          buttonType='text'
          sx={{
            height: 40,
            marginBottom: 4,
            textDecoration: "underline",
            "&:hover": {
              backgroundColor: "unset"
            }
          }}
          onClick={() => setNoTimesWork(true)}
        >
          No Times Work
        </CabButton>
      </Box>
    </Box>
  );
};

export default MobileDateTimeSelection;